import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Login.scss';

export const LoginPage = () => {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();
  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : '/welcome';

  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);

  //console.log(url,inviteMatches,orgMatches)

  useEffect(() => {
    if (inviteMatches && orgMatches) {
      loginWithRedirect({
        organization: orgMatches[1],
        invitation: inviteMatches[1],
        appState: {
          returnTo: '/',
        },
      });
    }

  }, [inviteMatches,orgMatches ]);

  const handleSubmit = () => {
    loginWithRedirect()
  };

  return (
    <div className="outer">
      <div className="login-container">
        <button onClick={handleSubmit} className="login-button">
          Login
        </button>
        <p className="container-prompt">You must log in to view this page</p>
      </div>
    </div>
  );
};
