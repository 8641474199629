
import { useAuth0 } from '@auth0/auth0-react';
import Header from '../components/header';

const Dashboard = () => {
  const { isAuthenticated } = useAuth0()

  return (
    <div className={isAuthenticated?"outer_container  p-7": 'hidden' }>
      <Header/>
      <div className="flex flex-col pt-10 ">
        <h3>Willkommen</h3>
      </div>
    </div>
  );
};

export default Dashboard;
