import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Welcome from './pages/Welcome';
import { LoginPage } from './pages/Login';
import NotFound from './pages/NotFound';
import { RequireAuth } from './utils/RequireAuth';
import { GlobalStateProvider } from "./utils/globalStateProvider";
import { useAuth0 } from '@auth0/auth0-react'
import { Spin,Result,Button } from 'antd';
import SalesforceSync from './pages/SalesforceSync'
import SalesforceDocumentsAsset from './pages/salesforce/documents/Asset';
import { SalesforceAuthCheck } from './pages/salesforce/SalesforceAuthCheck';
import { InitSalesforceIframeAuth } from './pages/salesforce/InitSalesforceIframeAuth';
import {IFramAuthDone} from './pages/salesforce/iFramAuthDone'
import AssetLibraryTable from './pages/salesforce/documents/AssetLibraryTable';

export default function App() {

  const { isLoading, error} = useAuth0();
  const { logout } = useAuth0()
  
  if (error) {
    return <Result
      status="error"
      title="Error"
      subTitle={error.message}
      extra={[
        <Button type="primary" key="console" onClick={()=>logout({ returnTo: window.location.origin+'/logout' })}>Home</Button>,
      ]}
    >
  </Result>;
  }

  if (isLoading) {
    return <div className="flex h-screen"><div className="m-auto"><Spin size="large" /></div></div>;
  }

  return (
    <GlobalStateProvider>
      <Routes>
        <Route path="/" element={<Navigate to={'/welcome'} />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LoginPage />} />
        <Route path="/salesforce/redirect/asset/:assetId" element={<SalesforceAuthCheck />} />
        <Route path="/salesforce/init-salesforce-iframe-auth" element={<InitSalesforceIframeAuth />} />
        <Route path="/salesforce/iFramAuthDone" element={<IFramAuthDone />} />
        <Route element={<RequireAuth />}>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/salesforce/documents/asset/:assetId" element={<SalesforceDocumentsAsset />} />
          <Route path="/salesforce/documents/asset/:assetId/:libraryId" element={<AssetLibraryTable />} />
          <Route path="/salesforce-sync" element={<SalesforceSync />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </GlobalStateProvider>
  );
}
