import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Category } from '../interfaces/Category';
import { categories as mockedCategories } from '../mocks/categories';

const CreateLibrary = ({}: {}) => {

    let { assetId } = useParams();
    const [categories, setCategories] = useState<Category[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        setCategories(mockedCategories)
      }, []);
    
    const clickCategory = (id:string) => {
        navigate('/salesforce/documents/asset/' + assetId + '/' + id);
    }

    return (
        <div className="grid grid-cols-2 gap-4">
            {categories.map((category)=>{
                return <div key={category._id} onClick={()=>{clickCategory(category._id)}} className="border p-4 flex items-center justify-center flex-col"><div>{category.label}</div><div>({category.files?.length ? category.files.length :0})</div></div>
            })}
        </div>
    );
};

export default CreateLibrary;
