import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CreateLibrary from '../../../components/createLibrary';
import ShowLibrary from '../../../components/showLibrary';

const SalesforceDocumentsAsset = () => {
  
  const [forceCreateLibrary, setForceCreateLibrary] = useState<boolean>(true);
  const [showLibrary, setShowLibrary] = useState<boolean>(false);
  
  const { isAuthenticated } = useAuth0()
  let { assetId } = useParams();
  
  return (
    <div className={isAuthenticated? " h-screen flex items-center justify-center": 'hidden' }>
      {forceCreateLibrary && <div onClick={()=>{setForceCreateLibrary(false),setShowLibrary(true)}}><CreateLibrary /></div>}
      {showLibrary && <ShowLibrary />}
    </div>
  );
};

export default SalesforceDocumentsAsset;
