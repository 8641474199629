import { API_URL } from './consts';

export const iniateSync = async (accessToken:string): Promise<any> => {

  let formData = {
  }

  const response = await customFetchWithAuthHeader(`${API_URL}/api/v1/salesforce/sync`, {
    method: 'POST',
    body: JSON.stringify(formData) 
  },accessToken);
  return response;
};

const customFetchWithAuthHeader = async (url: string, options: RequestInit, accessToken:string) => {
  const headers = {
    Authorization: `Bearer `+accessToken,
    'Content-Type': 'application/json'
  };
  const response = await fetch(url, {
    ...options,
    headers,
  });
  return response;
};
