import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0_domain,auth0_clientId,auth0_audience, admin_org_id } from './utils/consts';

import history from "./utils/history";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const onRedirectCallback = (appState: any) => {
  let navTO = appState && appState.returnTo ? appState.returnTo : window.location.pathname
  window.location.href=navTO  
};

const providerConfig = {
  domain: auth0_domain,
  clientId: auth0_clientId,
  organization: admin_org_id,
    ...(auth0_audience? { audience: auth0_audience } : null ),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
