
import { useAuth0 } from '@auth0/auth0-react';
import { Button, message, Modal } from 'antd';
import React, { useState } from 'react';
import Header from '../components/header';
import { iniateSync } from '../utils/helpersFetch';

const SalesforceSync = () => {
  const { getAccessTokenSilently,isAuthenticated } = useAuth0();
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [resultOutput, setResultOutput] = useState<any[]>([]);
  
  const syncSalesforce = async () => {

    setIsSyncing(true)

    let accessToken =  await getAccessTokenSilently()
    let result =  await iniateSync(accessToken)

    if(result.status !== 200){
      let resultObj = await result.json()
      message.error(resultObj.message)
    }
    else{
      let resultObj = await result.json()
      let output = []
  
      for (const property in resultObj) {
        output.push(<div key={property}><strong >{property}</strong></div>)
        for (const supProperty in resultObj[property]) {
          output.push(<div key={property+'-'+supProperty}>{supProperty}:{resultObj[property][supProperty]}</div>)
        }
      }      
      setResultOutput(output)
      setIsModalOpen(true)
    }
    setIsSyncing(false)
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let date = new Date()
  let dateString =  'Time ' + (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ':' + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()

  return (
    <div className={isAuthenticated ? "outer_container  p-7": 'hidden'} >
      <Header/>
      <div className="flex flex-col pt-10 ">
        <h3>Salesforce sync</h3>
        <div className=" w-40">
          <Button loading={isSyncing} onClick={()=>{syncSalesforce()}} type="primary">Sync starten</Button>
        </div><br/>
        Syncs orgninzations, contracts and assets from Salesforce.
        <Modal title={dateString} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={[
                  <Button key="back" onClick={handleOk} type="primary">
                    ok
                  </Button>
                ]}
        >
        {resultOutput}
      </Modal>        
      </div>
    </div>
  );
};

export default SalesforceSync;
