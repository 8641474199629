import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Category } from '../../../interfaces/Category';
import { categories as mockedCategories } from '../../../mocks/categories';
import './AssetLibraryTable.css'

const AssetLibraryTable = () => {
  
  const { isAuthenticated } = useAuth0()
  const [category, setCategory] = useState<Category|undefined>(undefined);
  let { assetId, libraryId } = useParams();
  
  useEffect(() => {
    mockedCategories.forEach((category) => {
      if(category._id === libraryId){
        setCategory(category)
      }
    })
  }, []);

  useEffect(() => {
    console.log(category)
  }, [category]);

  return (
    <div className={isAuthenticated? " h-screen flex items-center justify-center": 'hidden' }>
      <div>
        <table>
          <thead>
            <tr>
              <th>Sub category</th>
              <th>Title</th>
              <th>Added</th>
              <th>Size</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody >
            {category?.files?.map((file) => {
              return <tr >
                    <td>{file.subCategory}</td>
                    <td>{file.title}</td>
                    <td>{file.added}</td>
                    <td>{file.size}</td>
                    <td>Download</td>
                  </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssetLibraryTable;
