import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './../Login.scss';

export const InitSalesforceIframeAuth = () => {
  const location = useLocation();
  const locationSplitted = location.pathname.split('/')
  const { loginWithRedirect,isAuthenticated } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      appState: {
        returnTo: '/salesforce/iFramAuthDone',      
    },})
  }, []);


  return (
    <div className="outer">
      <div className="flex items-center flex-col justify-between w-full">
        you are getting redirected
      </div>
    </div>
  );
};
