import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { JWTUser } from '../interfaces/JWTUser';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const Header = ({}: {}) => {

    const navigate = useNavigate();
    const { user, isAuthenticated,getAccessTokenSilently, logout } = useAuth0()
    const [hasWriteSyncPermission, setHasWriteSyncPermission] = useState<boolean>(false);
    const [name, setName] = useState<string>('');

    useEffect(() => {
        if (isAuthenticated) {
            const checkRights = async () => {
                let accessToken =  await getAccessTokenSilently()
                if (accessToken){
                  let tokeninfo = jwt_decode<JWTUser>(accessToken)
                  if(tokeninfo.permissions.includes("write:sf-sync")){
                    setHasWriteSyncPermission(true)
                  }
                }
            }
            checkRights()
        }
      }, [isAuthenticated]);
    
      useEffect(() => {
        if(isAuthenticated && user && user.email){
            //img = user.picture?<img src={user.picture} alt={user.name} />:<></>
            setName(user.email)
        }
      }, [isAuthenticated, user]);

    return (
        <div className="flex">
            <span className=" pr-3 cursor-pointer underline " onClick={() => navigate('/welcome')}>Home</span>
            {hasWriteSyncPermission &&
            <span className=" grow cursor-pointer underline" onClick={() => navigate('/salesforce-sync')}>Salesforce-Sync</span>
            }
            <span className=" grow cursor-pointer text-end" onClick={()=>logout({ returnTo: window.location.origin + '/logout' })}>
                <span className="text-greyLight">eingeloggt als {name}</span><br/>
                Logout
            </span>
        </div>
    );
};

export default Header;
