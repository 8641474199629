import { Category } from "../interfaces/Category";

const categoriesBills:Category = {
    _id:'idbills',
    label:'Bills',
    files:[
        {
            _id:'id1',
            s3Key:'s3key',
            size:'12345',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        },
        {
            _id:'id2',
            s3Key:'s3key',
            size:'123456',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        },
        {
            _id:'id3',
            s3Key:'s3key',
            size:'123456',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        },
        {
            _id:'id4',
            s3Key:'s3key',
            size:'123456',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        }
    ]
  }

  const categoriesContracts:Category = {
    _id:'idcontracts',
    label:'Contracts',
    files:[
        {
            _id:'id1',
            s3Key:'s3key',
            size:'12345',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        },
        {
            _id:'id2',
            s3Key:'s3key',
            size:'123456',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        }
    ]
  }

  const categoriesPhotos:Category = {
    _id:'idphotos',
    label:'Photos',
    files:[
        {
            _id:'id1',
            s3Key:'s3key',
            size:'12345',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        },
        {
            _id:'id2',
            s3Key:'s3key',
            size:'123456',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        }
    ]
  }

  const categoriesDocuments:Category = {
    _id:'iddocuments',
    label:'Documents',
    files:[
        {
            _id:'id1',
            s3Key:'s3key',
            size:'12345',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        },
        {
            _id:'id2',
            s3Key:'s3key',
            size:'123456',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        },
        {
            _id:'id3',
            s3Key:'s3key',
            size:'123456',
            subCategory:'subcategory',
            title:'title',
            added:'added by'
        }
    ]
  }

  export const categories:Category[] = [categoriesBills,categoriesContracts,categoriesPhotos,categoriesDocuments]
