import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../Login.scss';

export const SalesforceAuthCheck = () => {
  const location = useLocation();
  const locationSplitted = location.pathname.split('/')
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      if(locationSplitted[3] === 'asset'){
        navigate('/salesforce/documents/asset/' + locationSplitted[4])
      }
    }
  }, [isAuthenticated ]);

  return (
    <div className="outer">
      <div className="flex items-center flex-col justify-between w-full">
        {!isAuthenticated && 'You must log in to view this page'}
        <a href="/salesforce/init-salesforce-iframe-auth" target="_blank" rel="opener">
          <button  className="login-button mt-6">Login</button>
        </a>
      </div>
    </div>
  );
};
