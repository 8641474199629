
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {categories}  from './../mocks/categories'

const CreateLibrary = ({}: {}) => {

    let { assetId } = useParams();

    useEffect(() => {
     
      }, []);
    
    return (
        <div className="">
            <button className="border p-4">create library</button>
        </div>
    );
};

export default CreateLibrary;
